.Overview{
    padding: 4rem 3rem;
    background-color:  rgb(8, 8, 115);
    color: white;
   
}
.Overview h1{
    font-size: 3rem;
    font-weight: 800;
}
.Overview p{
    font-size: 1rem;
    font-weight: 500;
}
.highlights h1{
    font-size: 2rem;
    font-weight: 800;
}
.main{
    background-color: #91b0fe;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
	position: relative;
    
}
.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 20px;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 550px;
}
.box {
    padding: 3rem;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
}
.highlights{
    background-color: rgb(8, 8, 115);
    color: white;
    padding: 2rem 3rem;

}
.highlights li{
    text-align: justify;
    font-size: 1rem;
}
.iternary{
    
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: 1.3rem;
    font-weight: 800;
    background-color:  rgb(8, 8, 115);
    color: white;

}
@media screen and (max-width: 850px) {
    .Overview {
        padding: 2rem 1.5rem;
    }

    .Overview h1 {
        font-size: 1.5rem;
    }

    .Overview p {
        font-size: 0.7rem;
        text-align: justify;
    }

    .main {
        flex-direction: column;
        align-items: center;
    }

    .container {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 1rem;
    }

    .box {
        padding: 1.5rem;
    }

    .highlights {
        padding: 1.5rem 1.5rem;
    }
    .highlights h1{
        font-size: 1.5rem;
    }
    .highlights p{
        font-size: 0.7rem;
        text-align: justify;
    }
    .iternary {
        font-size: 1rem;
        text-align: left;
    }
}