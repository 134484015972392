.adv {
  margin: 4rem 6rem;
  color: #2a2a2a;
}
.adv h1 {
  font-size: 3rem;
}
.advcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
.adv-card {
  width: 40%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
  transition: box-shadow 0.3s ease-in-out;
}
.adv-card:hover {
  box-shadow: 0 8px 30px 3px rgba(0, 0, 0, 0.15);
}
.adv-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}
.adv-image img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  border-radius: 7px;
}
.adv-image:hover img {
  transform: scale(1.3);
}
.adv-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}
.adv-card a {
  background-color: rgb(0, 5, 136);
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0.2rem 0.8rem;
  color: #ffffff;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.adv-card a:hover {
  background-color: black;
  color: white;
}
.adv-card a:focus {
  outline: 2px solid aqua;
  background-color: #028482; /* darker shade of aqua */
  color: #fff;
}
@media screen and (max-width: 850px) {
  .adv {
    margin: 4rem 2rem;
  }
  .advcard {
    flex-direction: column;
  }
  .adv-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
