.accordion-item {
  border: 1px solid #ccc;
  margin-bottom: 1.5rem;

}

.accordion-title {
  background-color: #f4f4f4;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-title span{
  font-size: 1rem;
  background-color: orange;
  font-weight: 900;
  padding: 0.8rem;
}
.accordion-content {
  padding: 10px;
  justify-content: stretch;
  background-color: #fff;
}
.main{
  background-color: #ded9d9;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
position: relative;
  
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 20px;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 550px;
}
.box {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .accordion-title {
    font-size: 0.8rem;
   
  }

  .accordion-content {
    padding: 6px;
  }

  .main {
    flex-direction: column;
    align-items: center;
  }

  .container {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px;
  }

  .box {
    padding: 15px;
  }
}