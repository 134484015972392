.honeymoon-section {
    background-color: #f5f5f5;
    padding: 30px 20px;

}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.section-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: darkblue;
    margin-bottom: 20px;
}

.section-subtitle {
    text-align: center;
    color: #777;
    margin-bottom: 40px;
}


.destination-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 20px;
}

.destination-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.destination-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.destination-info {
    padding: 20px;
}

.destination-name {
    font-size: 20px;
    font-weight: 600;
    color: darkblue;
}

.destination-description {
    color: black;
    margin-top: 10px;
}


.packages-section {
    margin-top: 60px;
    text-align: center; 
}

.package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.package-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.package-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.package-description {
    color: #555;
}
.package-booking{
    width: 100%;
  padding: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration:none;
}
/* Media Queries for Screens less than or equal to 850px */
@media screen and (max-width: 850px) {
    .container {
        padding: 0 15px;
    }

    .section-title {
        font-size: 28px;
    }

    .destination-grid, .package-grid {
        grid-template-columns: 1fr;
    }

    .destination-card, .package-card {
        margin-bottom: 20px;
    }

    .destination-image {
        height: 150px;
    }

    .destination-info, .package-card {
        padding: 15px;
    }

    .destination-name, .package-title {
        font-size: 18px;
    }

    .destination-description, .package-description {
        font-size: 14px;
    }
}
