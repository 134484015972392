.gallery-grid {
  margin-top: 8rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.gallery-item img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.gallery-item img:hover {
  transform: scale(1.05);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  object-fit: cover;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  color: white;
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the container without distortion */
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    object-fit: contain; /* Ensures the image fits within the lightbox without zooming in */
  }

  .close {
    font-size: 30px;
    top: 30px;
    right: 20px;
    color: black;
  }
}

