/* SignUp page container */
.signup-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://media.istockphoto.com/id/1276682837/photo/trekking-way-on-top-of-the-mountains-and-sunset-view.jpg?s=170667a&w=0&k=20&c=AVJrWFcWnlPeihjO7y6Q8nkMmUmNcf-VOO3YsrjpbSw="); 
  background-size: cover;
  background-position: center center;
  position: relative;
}

.signup-container {
  display: flex;
  width: 60%;
  height: 70%;
  border-radius: 10px;
  overflow: hidden;
}

/* Left Form Section */
.form-section {
  width: 50%;
  padding: 50px;

  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  font-size: 2rem;
}

p {
  margin-bottom: 30px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
}

.signup-button {
  width: 100%;
  padding: 15px;
  background-color: #ff7e67;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.signup-button:hover {
  background-color: #ff5b3b;
}

/* Error and Success Messages */
.error-message {
  color: #ff4d4d;
  font-size: 0.875rem;
  margin: 5px 0 10px;
}

.success-message {
  color: #4caf50;
  font-size: 1rem;
  margin-top: 10px;
}

/* Signin link */
.signin-link {
  margin-top: 20px;
  text-align: center;
}

.signin-link a {
  color: #ff7e67;
  text-decoration: none;
}

.signin-link a:hover {
  text-decoration: underline;
}

/* Right Quote Section */
.quote-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  font-weight:800;
}

blockquote {
  font-size: 1.5rem;
  text-align: center;
  font-style: italic;
}

/* Back and Traveler Text */
.back-button, .new-traveler {
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  color:black;
  font-weight:700;
}

.back-button:hover, .new-traveler:hover {
  text-decoration: underline;
}


@media screen and (max-width: 850px) {
  .signup-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }

  .form-section, .quote-section {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
  }

  input {
    padding: 8px;
    margin: 8px 0;
  }

  .signup-button {
    padding: 12px;
    font-size: 1rem;
  }

  blockquote {
    font-size: 1.2rem;
    padding: 20px;
  }

  .signin-link {
    font-size: 0.875rem;
  }
}